/**
 * id：用于存储权限
 * name：权限名称
 * icon：菜单上的图标
 * route：跳转的页面路径
 * show：是否显示菜单
 */
export const menuList = [{
		id: '1-1',
		name: '系统管理',
		icon: 'el-icon-setting',
		route: '',
		show: true,
		children: [{
			id: '1-1-1',
			name: '用户管理',
			icon: '',
			route: '/system/user',
			show: true,
		}, {
			id: '1-2-1',
			name: '系统配置',
			icon: '',
			route: '/system/config',
			show: true,
		}, ],
	},
	{
		id: '2-1',
		name: '商品管理',
		icon: 'el-icon-shopping-bag-1',
		route: '',
		show: true,
		children: [{
			id: '2-1-1',
			name: '商品信息',
			icon: '',
			route: '/goods/goods',
			show: true,
		}, {
			id: '2-1-2',
			name: '商品优惠',
			icon: '',
			route: '/goods/discount',
			show: true,
		}, {
			id: '2-1-3',
			name: '商品推荐',
			icon: '',
			route: '/goods/recommend',
			show: true,
		}, {
			id: '2-1-4',
			name: '体检管理',
			icon: '',
			route: '/goods/medicalExam',
			show: true,
		}, ],
	},
	{
		id: '3-1',
		name: '订单管理',
		icon: 'el-icon-notebook-2',
		route: '',
		show: true,
		children: [{
			id: '3-1-1',
			name: '订单处理',
			icon: '',
			route: '/order/order',
			show: true,
		}, {
			id: '3-2-1',
			name: '【权限点】订单导出',
			icon: '',
			route: '',
			show: false,
		}, {
			id: '3-2-2',
			name: '【权限点】订单退款',
			icon: '',
			route: '',
			show: false,
		}, {
			id: '3-3-1',
			name: '体检订单',
			icon: '',
			route: '/order/kkOrder',
			show: true,
		}, {
			id: '3-3-2',
			name: '【权限点】体检订单导出',
			icon: '',
			route: '',
			show: false,
		}, {
			id: '3-3-3',
			name: '【权限点】体检订单退款',
			icon: '',
			route: '',
			show: false,
		}, ],
	},
	{
		id: '4-1',
		name: '首页管理',
		icon: 'el-icon-house',
		route: '',
		show: true,
		children: [{
			id: '4-1-1',
			name: '轮播图管理',
			icon: '',
			route: '/home/banner',
			show: true,
		}, {
			id: '4-1-2',
			name: '板块管理',
			icon: '',
			route: '/home/module',
			show: true,
		}, ],
	},
	{
		id: '5-1',
		name: '报表查询',
		icon: 'el-icon-reading',
		route: '',
		show: true,
		children: [{
			id: '5-1-1',
			name: '佣金统计',
			icon: '',
			route: '/report/commission',
			show: true,
		}, {
			id: '5-1-2',
			name: '经销收益',
			icon: '',
			route: '/report/profit',
			show: true,
		}, ],
	},
];